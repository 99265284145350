<template>
  <div class="actions">
    {{ libelle }}
  </div>
</template>
  
  <script>
  import {mapGetters} from 'vuex'
  export default {
      props : {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
          
      },
      computed: {
          ...mapGetters({
              packs: 'packs/packs',
          }),
          pack(){
            return this.packs.find(item => item.uid === this.object.pack)
          },
          hasPack(){return this.pack !== null && this.pack !== undefined},
          libelle(){return this.hasPack ? this.pack.libelle: '-'}
      }
  
  }
  </script>
  
  <style>
  
  </style>